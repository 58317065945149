import React, { useEffect, useState } from "react";
import { graphql } from 'gatsby'
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Banner from "../components/Banner/Banner";
import ImageCard from "../components/ImageCard/ImageCard";
import TileBlock from "../components/TileBlock/TileBlock";
import MarkettingBanner from "../components/MarkettingBanner/MarkettingBanner";
import LocationList from "../components/ImageCard/LocationList";
import Reviews from "../components/Reviews/Reviews";
import HomeAccordion from "../components/HomeAccordion/HomeAccordion";
import HomeNews from "../components/HomeNews/HomeNews";
import Seo from "../components/seo"


const HomePageTemplate = ({ data }, props) => {
    const PageData = data?.strapiPage
    return (
        <Layout popularSearch={PageData?.select_popular_search?.title}>
             {PageData.banner && PageData.banner?.image && <Banner
              {...PageData.banner}
            //  id={PageData.strapi_id} imagetransforms={PageData.imagetransforms} tag="home"
            pageName={PageData?.title}
            bannerData={PageData?.banner}
            // layout={PageData.layout}
            // menuSlug={PageData?.menu?.slug}
            imagetransforms={PageData?.imagetransforms?.internal?.content}
            strapiID={PageData?.strapi_id}
            ggfx_results={PageData?.ggfx_results}
              /> }
            {PageData?.add_page_modules?.length > 0 && PageData.add_page_modules?.map((module, index) => {
                return (
                    <>
                        {module.strapi_component === "page-modules.services-cta" && 
                        <ImageCard                     
                        {...module}
                        slide_count={PageData.count === "five" ? 5 : PageData.count === "four" ? 4 : 4}
                        strapiID={PageData?.strapi_id}
                        ggfx_results={PageData?.ggfx_results}
                        imagetransforms={PageData?.imagetransforms?.internal?.content} 
                        
                        />
                        }
                         {module.strapi_component === "page-modules.tile-block" && 
                        <TileBlock                     
                        {...module}
                        strapiID={PageData?.strapi_id}
                        ggfx_results={PageData?.ggfx_results}
                        imagetransforms={PageData?.imagetransforms?.internal?.content}  />
                        }
                         {module.strapi_component === "page-modules.marketting-banner" && 
                        <MarkettingBanner  
                        // strapiID={PageData?.strapi_id}
                        // ggfx_results={PageData?.ggfx_results}
                        // imagetransforms={PageData?.imagetransforms?.internal?.content}                   
                        {...module}
                        />
                        }

{module.strapi_component === "page-modules.global-module" && module.select_module === "location_search_list" &&
                        <><LocationList                     
                        {...module}
                        />
                        <HomeNews/></>
                        }
                        {module.strapi_component === "page-modules.global-module" &&  module.select_module === "review_slider" &&
                        <Reviews                     
                        {...module}
                        />
                        }
                        {module.strapi_component === "page-modules.accordion" &&
                        <>
                       
                        <HomeAccordion  {...module} />
                        </>
                    }
                    </>
                )
            })}
            {/* <FooterCTAModule /> */}
        </Layout>
    )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiPage
  return (
      <Seo title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} />
  )
}

export default HomePageTemplate

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: {eq: $page_id}) {
        ...PageFragment
        add_page_modules {
            ... on STRAPI__COMPONENT_PAGE_MODULES_TILE_BLOCK {
              ...TailBlockFragment
            }
            ... on STRAPI__COMPONENT_PAGE_MODULES_SERVICES_CTA {
                ...ServiceCtaFragment
              }
              ... on STRAPI__COMPONENT_PAGE_MODULES_MARKETTING_BANNER {
              ...markettingBannerFragment
              }
              ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
              ...GlobalModuleFragment
              }
              ... on STRAPI__COMPONENT_PAGE_MODULES_ACCORDION {
              ...AccordionFragment
              }
        }
    }
  }
`